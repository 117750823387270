import Api from '@/services/Index';

const getSchedulesInstructor = (module) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/schedules/instructor/' + module);
}

const getPrepExams = (class_id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/classes/' + class_id + '/exams');
}

const getPrepExamStudents = (exam_id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/exams/' + exam_id + '/students');
}

const getStudents = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/students', config);
}

const postPrepExamScore = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exam/scores', formdata);
}

const putPrepExamFinalized = (exam_id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/prep/exams/' + exam_id + '/finalize');
}

const postPrepExamAdd = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exams', formdata);
}

const scoreReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exam/scores/report', formdata, {responseType: 'arraybuffer'});
}

const averageReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exam/scores/average-report', formdata, {responseType: 'arraybuffer'});
}

const successRateReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exam/scores/success-rate-report', formdata, {responseType: 'arraybuffer'});
}

const statusReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/placement/exams/proficiency-report', formdata, {responseType: 'arraybuffer'});
}

const prepAttendanceStatusReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/attendances/status-report', formdata, {responseType: 'arraybuffer'});
}

const prepExamStatusReport = (formdata) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/prep/exams/status-report', formdata, {responseType: 'arraybuffer'});
}

const getSuccessStatusReport = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/prep/exams/success-status-report', config);
}

const placementExamReport = (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/placement/exams/excel-export', {...config, responseType: 'arraybuffer'});
}

const updatePublishStatus = (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/prep/exams/'+id+'/finalize-restore', );
}




export default {
    getSchedulesInstructor,
    getStudents,
    getPrepExams,
    getPrepExamStudents,
    postPrepExamScore,
    putPrepExamFinalized,
    postPrepExamAdd,

    scoreReport,
    averageReport,
    successRateReport,
    statusReport,
    prepAttendanceStatusReport,
    prepExamStatusReport,
    getSuccessStatusReport,
    placementExamReport,
    updatePublishStatus
}
